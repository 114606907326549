<template>
  <b-overlay :show="navigationBusy">
    <portlet title="يېڭىدىن باشلاش تىزىملىكى قوشۇش">
      <template v-slot:body>
        <navigation-form
          ref="navigation"
          view-type="navigation"
          @storing="onStoring"
          @stored="onStored"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NavigationForm from "@v@/components/forms/NavigationForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { debugConsole } from "@c@/helpers/helpers";
  export default {
    name: "index",
    components: { Portlet, NavigationForm },
    mixins: [ formBusyMixin ],
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'باشلاش تىزىملىكى باشقۇرۇش', route: {name: 'navigation.home'}});
      BreadCrumbs.addBreadCrumb({title: 'يېڭىدىن تىزىملىك قوشۇش'});
      if ( this.$refs.hasOwnProperty('navigation') ){
        this.form = this.$refs['navigation'];
      }
    },
    data(){
      return {
        navigationBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onStoring(){
        this.showBusy('navigationBusy');
        debugConsole({prefix: 'navigation', message: `onStored`});
      },
      onStored(state){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'navigation', message: `onStored: ${state}`});
        this.$router.replace({name: 'navigation.home'});
      }
    }
  }
</script>

<style scoped>

</style>
