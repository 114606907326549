<template>
  <b-overlay :show="navigationBusy">
    <portlet title="ئېلان ئۇچۇرى تەھرىرلەش">
      <template v-slot:body>
        <navigation-form
          action="edit"
          ref="navigation"
          view-type="navigation"
          :model-id="navigationId"
          @loading="onLoading"
          @loaded="onLoaded"
          @updating="onLoading"
          @updated="onUpdated"
        />
      </template>
      <template v-slot:foot>
        <b-button id="submit" @click="handleSubmit" variant="primary">
          ساقلاش
        </b-button>
        <b-button class="ml-2" variant="danger" @click="onClickReset">
          بىكار قىلىش
        </b-button>
      </template>
    </portlet>
  </b-overlay>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import NavigationForm from "@v@/components/forms/NavigationForm";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import {debugConsole} from "@c@/helpers/helpers";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "index",
    components: { Portlet, NavigationForm },
    mixins: [ formBusyMixin, sweetAlertMixin ],
    computed: {
      navigationId(){
        if ( this.$route.hasOwnProperty('params') )
          return this.$route.params.id;
      },
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: 'باشلاش تىزىملىكى باشقۇرۇش', route: {name: 'navigation.home'}});
      BreadCrumbs.addBreadCrumb({title: ' تىزىملىك ئۇچۇرى تەھرىرلەش'});
      if ( this.$refs.hasOwnProperty('navigation') ){
        this.form = this.$refs['navigation'];
      }
    },
    data(){
      return {
        navigationBusy: false,
        form: {}
      };
    },
    methods: {
      handleSubmit(){
        this.form.onSubmit();
      },
      onLoading(){
        this.showBusy('navigationBusy');
        debugConsole({prefix: 'navigation', message: 'onLoading'})
      },
      onLoaded({response, state}){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'navigation', message: `onLoaded: ${state}`});
      },
      onUpdated({response, state}){
        this.hideBusy('navigationBusy');
        debugConsole({prefix: 'navigation', message: `onUpdated: ${state}`});
        if ( state === 'success' ){
          this.success({ message: 'باشلاش تىزىملىكى ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى.', title: '' });
        }
      },
      onClickReset(){
        this.$router.replace({name: 'navigation.home'});
      },
    }
  }
</script>

<style scoped>

</style>
